<template>
  <MainLoader />
  <HeaderMenu
    @click="this.$store.state['SideMenuIndex'] = 0"
    v-if="this.$store.state['is_login'] == 0"
  />
  <router-view
    @click="this.$store.state['SideMenuIndex'] = 0"
    :style="`height: calc(${this.$store.state['CurrentHeight']}px - 
    ${this.$store.state['DownMenuHeight'] + this.$store.state['HeaderMenuHeight']}px)`"
  />
  <DownMenu v-if="this.$store.state['is_login'] == 0" />
  <SideMenu v-if="this.$store.state['is_login'] == 0" />
</template>

<script>
import MainLoader from "@/components/MainLoader.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";
import DownMenu from "@/components/DownMenu.vue";
import SideMenu from "@/components/SideMenu.vue";
import axios from "axios";
import $ from "jquery";
window.$ = window.jQuery = require("jquery");
export default {
  name: "AppView",
  components: {
    MainLoader,
    HeaderMenu,
    DownMenu,
    SideMenu,
  },
  data() {
    return {};
  },
  created() {
    let main = this;
    $("body").css("height", window.innerHeight + "px");
    main.$store.state["DownMenuHeight"] = $("#DownMenu").outerHeight();
    main.$store.state["HeaderMenuHeight"] = $("#HeaderMenu").outerHeight();
  },
  mounted() {
    let main = this;
    $(window).resize(function () {
      $("body").css("height", window.innerHeight + "px");
      main.$store.state["CurrentWidth"] = window.innerWidth;
      main.$store.state["CurrentHeight"] = window.innerHeight;
      main.$store.state["DownMenuHeight"] = $("#DownMenu").outerHeight();
      main.$store.state["HeaderMenuHeight"] = $("#HeaderMenu").outerHeight();
    });
  },
};
</script>

<style lang="scss">
// @font-face {
//   font-family: LogoFont;
//   src: url("@/assets/bodoni bold condensed bt.ttf");
// }
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;900&family=Oxygen:wght@300;400;700&family=Roboto:wght@400;700;900&family=Signika:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@100;300;400;600;700;800;900&display=swap");

:root {
  --LightGrey: #eaeaea;
  --DarkGrey: #ababac;
  --Black: #051515;
  --LightGreyGreen: #c8cfb5;
  --DarkBlue: #031926;
  --LightBlue: #9dbebb;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // font-family: "Montserrat", sans-serif;
  // font-family: "Oxygen", sans-serif;
  // font-family: "Roboto", sans-serif;
  // font-family: "Signika", sans-serif;
  font-family: "Alexandria", sans-serif;
}

html {
  scroll-behavior: smooth;
}

p,
h1,
h2,
h3,
span,
ul {
  margin: 0 !important;
}

body {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;

  #app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
    // @media screen and (max-width: 767px) {
    //   padding: 0 1rem;
    // }
  }
}

@media screen and (min-width: 100px) {
  /* font at xs */
  html {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 767px) {
  /* font at sm */
  html {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 991px) {
  /* font at md */
  html {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1200px) {
  /* font at lg */
  html {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 1400px) {
  /* font at xl */
  html {
    font-size: 18px !important;
  }
}
.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-active {
  transition: all 500 ease;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: all 500ms ease;
}
</style>
