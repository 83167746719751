<template>
  <div class="col-12" id="HeaderMenu">
    <img src="@/assets/logo.png" />
    <h1>{{ this.$store.state["CurrentPage"]["ArName"] }}</h1>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "HeaderMenu",
  components: {},
  data() {
    return {
      Api_Url: this.$store.state["Api_Url"],
    };
  },
  created() {},
  mounted() {
    this.$store.state["HeaderMenuHeight"] = $("#HeaderMenu").outerHeight();
  },
  computed() {},
  methods: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
#HeaderMenu {
  display: flex;
  width: 100%;
  background-color: white;

  border-top: 1px solid lightgrey;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  img {
    height: 4rem;
    width: 4rem;
  }
  h1 {
    font-size: 2.1rem;
    flex-grow: 1;
    text-align: center;
  }
}
</style>
