<template>
  <div class="col-12" id="DownMenu">
    <div
      v-for="(Action, index) in this.MenuActions"
      :key="Action"
      :class="Action['Is_Open'] == 1 ? 'Icon-Container ActiveAction' : 'Icon-Container '"
      :style="'width:' + 100 / this.MenuActions.length + '%'"
      @click="this.OpenPage(Action['View'], index)"
    >
      <font-awesome-icon :icon="Action['Icon']" class="Action" />
      <p>{{ Action["ArName"] }}</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "DownMenu",
  components: {},
  data() {
    return {
      Api_Url: this.$store.state["Api_Url"],
      MenuActions: [
        { View: "home", Icon: "fa-solid fa-grip", ArName: "الرئيسية", Is_Open: 1 },
        { View: "wallet", Icon: "fa-solid fa-wallet", ArName: "العهدة", Is_Open: 0 },
        {
          View: "open_production",
          Icon: "fa-regular fa-bell",
          ArName: "المشروعات",
          Is_Open: 0,
        },
        { View: "", Icon: "fa-solid fa-bars", ArName: "المزيد", Is_Open: 0 },
      ],
    };
  },
  created() {
    this.$store.state["CurrentPage"] = this.MenuActions[0];
  },
  mounted() {
    this.$store.state["DownMenuHeight"] = $("#DownMenu").outerHeight();
  },
  computed() {},
  methods: {
    OpenPage(View, index) {
      if (index != 3) {
        this.$store.state["SideMenuIndex"] = 0;
        this.$router.push({ name: View });
        this.$store.state["CurrentPage"] = this.MenuActions[index];
        this.MenuActions.forEach((action) => {
          action["Is_Open"] = 0;
        });
        this.MenuActions[index]["Is_Open"] = 1;
      } else {
        if (this.$store.state["SideMenuIndex"] == 1) {
          this.$store.state["SideMenuIndex"] = 0;
        } else {
          this.$store.state["SideMenuIndex"] = 1;
        }
      }
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
#DownMenu {
  display: flex;
  width: 100%;
  background-color: white;
  border-top: 1px solid lightgrey;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  .Icon-Container {
    position: relative;
    width: 25%;
    transition: ease all 400ms;
    cursor: pointer;
    .Action {
      height: 2.4rem;
      padding: 1.5rem 0;
      width: 100%;
    }
    p {
      font-size: 1rem;
      text-align: center;
      position: absolute;
      bottom: 0.4rem;
      width: 100%;
    }
  }
  .Icon-Container:hover {
    color: #8085ff;
  }
  .ActiveAction {
    color: #8085ff;
  }
}
</style>
