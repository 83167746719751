<template>
  <Transition name="slide-fade">
    <div
      v-if="this.$store.state['SideMenuIndex'] == 1"
      id="SideMenu"
      @click="$event.stopPropagation()"
    >
      <ul class="col-12">
        <li
          v-for="(Action, index) in this.Menu_Actions"
          :key="Action"
          @click="this.OpenPage(Action['View'], index)"
        >
          <font-awesome-icon class="Icon" :icon="'fa-solid ' + Action['Action_Icon']" />
          {{ Action["ArName"] }}
        </li>
        <li @click="this.Logout()">
          <font-awesome-icon class="Icon" icon="fa-solid fa-right-from-bracket" />
          تسجيل الخروج
        </li>
      </ul>
    </div>
  </Transition>
</template>
<script>
// @ is an alias to /src
export default {
  name: "SideMenu",
  components: {},
  data() {
    return {
      Api_Url: this.$store.state["Api_Url"],
      Menu_Actions: [
        {
          ArName: "طلبات التسعير",
          Action_Icon: "fa-clipboard-list",
          View: "price_tasks",
        },
        {
          ArName: "المشاريع المنتهية",
          Action_Icon: "fa-clipboard-check",
          View: "done_production",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  computed() {},
  methods: {
    OpenPage(View, index) {
      this.$store.state["CurrentPage"] = this.Menu_Actions[index];
      this.$store.state["SideMenuIndex"] = 0;
      this.$router.push({ name: View });
    },
    Logout() {
      this.$store.state["SideMenuIndex"] = 0;
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
#SideMenu {
  position: fixed;
  width: 70%;
  display: flex;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 20;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #000000ed;
  color: white;
  font-size: 2rem;
  padding-top: 2rem;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    li {
      font-size: 1.8rem;
      margin-bottom: 2rem;
      list-style-type: none;
      text-align: right;
      direction: rtl;
      background-color: #191b4e;
      padding: 1.5rem 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      .Icon {
        height: 2.5rem;
        width: 2.5rem;
        margin: 0 1rem;
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-40px);
  opacity: 0;
}
</style>
