import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/price",
    name: "price_tasks",
    component: () => import("@/views/PriceTasks.vue"),
  },
  {
    path: "/production",
    name: "open_production",
    component: () => import("@/views/OpenProductionTasks.vue"),
  },
  {
    path: "/done",
    name: "done_production",
    component: () => import("@/views/DoneProductionTasks.vue"),
  },
  {
    path: "/wallet",
    name: "wallet",
    component: () => import("@/views/WalletView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "view404",
    component: () => import("@/views/View404.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
